import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFirstLast'
})
export class FirstLastPipe implements PipeTransform {
  transform<T>(array: T[]): [T | undefined, T | undefined] {
    if (!Array.isArray(array) || array.length === 0) {
      return [undefined, undefined];
    }

    return [array[0], array[array.length - 1]];
  }
}
