import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appLimitArray'
})
export class LimitArrayPipe implements PipeTransform {
  transform<T>(array: T[], limit: number, showHiddenCount: boolean = true): (T | string)[] {
    if (!Array.isArray(array) || array.length === 0) {
      return [];
    }

    const shownElements = array.slice(0, limit);
    const hiddenCount = array.length - limit;

    return showHiddenCount && hiddenCount > 0 ? [...shownElements, `+${hiddenCount}`] : shownElements;
  }
}
