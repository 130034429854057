import { CandidateHistoryEventType } from '../../../types/candidate-history';

export const activityData = {
  [CandidateHistoryEventType.CREATED]: {
    icon: 'fa_regular:circle-user',
    description: 'Candidate created',
  },
  [CandidateHistoryEventType.UPDATED]: {
    icon: 'fa_solid:arrows-rotate',
    description: 'Candidate updated',
  },
  [CandidateHistoryEventType.COMMENT_ADDED]: {
    icon: 'fa_solid:comment',
    description: 'Comment added',
  },
  [CandidateHistoryEventType.FEEDBACK_ADDED]: {
    icon: 'fa_solid:comment',
    description: 'Feedback added',
  },
  [CandidateHistoryEventType.TAG_ADDED]: {
    icon: 'fa_solid:user-tag',
    description: 'tag added'
  },
  [CandidateHistoryEventType.TAG_REMOVED]: {
    icon: 'fa_solid:trash',
    description: 'tag removed'
  },
  [CandidateHistoryEventType.APPLIED_TO_JOB]: {
    icon: 'fa_solid:id-card-clip',
    description: 'Applied to job'
  },
  [CandidateHistoryEventType.REMOVED_FROM_JOB]: {
    icon: 'fa_solid:trash',
    description: 'Removed from job'
  },
  [CandidateHistoryEventType.JOB_STAGE_CHANGED]: {
    icon: 'fa_solid:arrows-rotate',
    description: 'Job stage changed'
  },
  [CandidateHistoryEventType.STATUS_CHANGED]: {
    icon: 'fa_solid:arrows-rotate',
    description: 'Candidate status changed'
  },
  [CandidateHistoryEventType.FILE_ADDED]: {
    icon: 'fa_solid:folder-plus',
    description: 'File added'
  },
  [CandidateHistoryEventType.FILE_REMOVED]: {
    icon: 'fa_solid:folder-minus',
    description: 'File removed'
  },
  [CandidateHistoryEventType.RESPONSIBLE_ADDED]: {
    icon: 'fa_solid:user-plus',
    description: 'added as responsible'
  },
  [CandidateHistoryEventType.RESPONSIBLE_REMOVED]: {
    icon: 'fa_solid:user-xmark',
    description: 'removed as responsible'
  },
};
