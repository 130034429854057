<div [formGroup]="form" class="flex flex-col gap-3">
  <ng-container *ngIf="!editable; else edit">
    <div class="w-full" *ngFor="let contact of contactsFA.controls">
      <div class="flex">
        <mat-icon [svgIcon]="contactTypes[contact.get('type')?.value]?.icon"></mat-icon>
        <div class="min-w-0 ml-4 space-y-1">
          <div class="flex items-center leading-6" [ngSwitch]="contact.get('type')?.value">
            <a
              *ngSwitchCase="'email'"
              class="hover:underline text-primary-500 overflow-hidden text-ellipsis"
              [href]="'mailto:' + contact.get('value')?.value"
              [matTooltip]="contact.get('value')?.value"
              target="_blank"
            >
              {{ contact.get('value')?.value }}
            </a>

            <a
              *ngSwitchCase="'phone'"
              class="hover:underline text-primary-500 overflow-hidden text-ellipsis"
              [href]="'tel:' + contact.get('value')?.value"
              [matTooltip]="contact.get('value')?.value"
              target="_blank"
            >
              {{ contact.get('value')?.value }}
            </a>

            <a
              *ngSwitchDefault
              class="hover:underline text-primary-500 overflow-hidden text-ellipsis"
              [href]="contact.get('value')?.value"
              [matTooltip]="contact.get('value')?.value"
              target="_blank"
            >
              {{ contact.get('value')?.value }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #edit>
    <div>
      <div class="space-y-4">
        <ng-container *ngFor="let contact of contactsFA.controls; let i = index;" formArrayName="contacts">
          <div class="relative flex" [formGroupName]="i">
            <mat-form-field class="fuse-mat-no-subscript flex-auto">
              <mat-label class="capitalize">{{contact.get('type')?.value}}</mat-label>

              <input matInput formControlName="value">

              <mat-select class="mr-1.5" matPrefix formControlName="type">
                <mat-select-trigger>
                  <span class="flex items-center">
                    <mat-icon class="icon-size-4" [svgIcon]="contactTypes[contact.get('type')?.value]?.icon"></mat-icon>
                  </span>
                </mat-select-trigger>

                <mat-option *ngFor="let type of contactTypes | keyvalue" [value]="type.key">
                  <div class="flex items-center">
                    <mat-icon class="icon-size-4" [svgIcon]="type.value.icon"></mat-icon>
                    <span class="font-medium">{{ type.value.label }}</span>
                  </div>
                </mat-option>
              </mat-select>

              <mat-error *ngIf="contact.get('value').hasError('required')" @slideInDownOnEnter>
                <span class="capitalize">
                  {{contact.get('type')?.value}}
                </span>
                <span>is required</span>
              </mat-error>

              <mat-error *ngIf="contact.get('value').hasError('isMobilePhone')" @slideInDownOnEnter>
                <span>Wrong phone number</span>
              </mat-error>

              <mat-error *ngIf="contact.get('value').hasError('isEmail')" @slideInDownOnEnter>
                <span>Wrong email</span>
              </mat-error>

              <mat-error *ngIf="contact.get('value').hasError('isURL')" @slideInDownOnEnter>
                <span>Wrong URL</span>
              </mat-error>
            </mat-form-field>

            <div class="flex items-center w-10 pl-2">
              <button
                class="w-8 h-8 min-h-8"
                (click)="contactsFA.removeAt(i)"
                mat-icon-button
                matTooltip="Remove"
              >
                <mat-icon
                  class="icon-size-5"
                  svgIcon="heroicons_solid:trash"
                ></mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>

      <div
        class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
        (click)="addContact()"
      >
        <mat-icon
          class="icon-size-5"
          svgIcon="heroicons_solid:plus-circle"
        ></mat-icon>
        <span class="ml-2 font-medium text-secondary group-hover:underline">
          Add contact
        </span>
      </div>
    </div>

    <div
      *ngIf="showButtons"
      class="flex justify-end mt-4"
    >
      <button
        mat-button
        matTooltip="Cancel"
        (click)="cancelEdit()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        matTooltip="Save"
        [disabled]="form.invalid"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </ng-template>
</div>
