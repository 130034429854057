<ng-container *ngIf="user.avatar; else initials">
  <img
    class="object-cover w-full h-full ring-2 ring-bg-card cursor-default rounded-full"
    [src]="user.avatar.url"
    alt="Contact avatar"
    [matTooltip]="getTooltipText()"
    [matTooltipDisabled]="!showTooltip"
  />
</ng-container>

<ng-template #initials>
  <div
    [style.background-color]="getColor(user.id)"
    class="flex items-center justify-center w-full h-full rounded-full uppercase text-white ring-2 ring-bg-card cursor-default"
    [matTooltip]="getTooltipText()"
    [matTooltipDisabled]="!showTooltip"
  >
    <span *ngIf="user.firstName && user.lastName; else altView">
      {{ user.firstName && user.firstName.charAt(0) }}
      {{ user.lastName && user.lastName.charAt(0) }}
    </span>
  </div>
</ng-template>

<ng-template #altView>
  <span *ngIf="altProperty && user[altProperty]">
    {{ user[altProperty].charAt(0) }}
  </span>
</ng-template>
