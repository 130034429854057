import * as validator from 'validator';

export type ContactType =
  | 'phone'
  | 'email'
  | 'linkedin'
  | 'facebook'
  | 'telegram'
  | 'whatsapp'
  | 'viber'
  | 'skype'
  | 'github'
  | 'behance'
  | 'dribbble'
  | 'medium'
  | 'patreon'
  | 'stackoverflow'
  | 'twitter'
  | 'youtube'
  | 'webpage'
  | 'other';

export interface Contact {
  type: ContactType;
  label: string;
  validator: keyof typeof validator | null;
  icon: string;
}

export const contactTypes: Record<ContactType, Contact> = {
  phone: {type: 'phone', label: 'Phone', validator: 'isMobilePhone', icon: 'fa_solid:phone'},
  email: {type: 'email', label: 'Email', validator: 'isEmail', icon: 'fa_solid:envelope'},
  linkedin: {type: 'linkedin', label: 'LinkedIn', validator: 'isURL', icon: 'fa_brands:linkedin'},
  facebook: {type: 'facebook', label: 'Facebook', validator: 'isURL', icon: 'fa_brands:facebook'},
  telegram: {type: 'telegram', label: 'Telegram', validator: null, icon: 'fa_brands:telegram'},
  whatsapp: {type: 'whatsapp', label: 'Whatsapp', validator: null, icon: 'fa_brands:whatsapp'},
  viber: {type: 'viber', label: 'Viber', validator: null, icon: 'fa_brands:viber'},
  skype: {type: 'skype', label: 'Skype', validator: null, icon: 'fa_brands:skype'},
  github: {type: 'github', label: 'Github', validator: 'isURL', icon: 'fa_brands:github'},
  behance: {type: 'behance', label: 'Behance', validator: 'isURL', icon: 'fa_brands:behance'},
  dribbble: {type: 'dribbble', label: 'Dribbble', validator: 'isURL', icon: 'fa_brands:dribbble'},
  medium: {type: 'medium', label: 'Medium', validator: 'isURL', icon: 'fa_brands:medium'},
  patreon: {type: 'patreon', label: 'Patreon', validator: 'isURL', icon: 'fa_brands:patreon'},
  stackoverflow: {type: 'stackoverflow', label: 'Stackoverflow', validator: 'isURL', icon: 'fa_brands:stack-overflow'},
  twitter: {type: 'twitter', label: 'Twitter', validator: 'isURL', icon: 'fa_brands:x-twitter'},
  youtube: {type: 'youtube', label: 'Youtube', validator: 'isURL', icon: 'fa_brands:youtube'},
  webpage: {type: 'webpage', label: 'Webpage', validator: 'isURL', icon: 'fa_solid:house'},
  other: {type: 'other', label: 'Other', validator: 'isURL', icon: 'fa_solid:link'}
};
