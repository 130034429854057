import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BehaviorSubject, Observable, take, tap, timer } from 'rxjs';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({height: '{{height}}px'}), {params: {height: 0}}),
      state('expanded', style({height: '*'})),
      transition('collapsed <=> expanded', animate('300ms ease-in-out')),
    ])
  ]
})
export class ShowMoreComponent implements AfterViewChecked {
  @Input() maxLength: number = 200;
  @Input() moreLabel: string = 'Show more';
  @Input() lessLabel: string = 'Show less';
  @ViewChild('contentContainer', { static: false }) contentContainer!: ElementRef;

  public textSubject = new BehaviorSubject<string>('');
  public text$: Observable<string> = this.textSubject.asObservable();

  public initialHeight = 0;
  public isExpanded = false;
  public isLongText = false;
  private isHeightSet = false;
  private fullText!: string;

  constructor(private cdr: ChangeDetectorRef) {}

  @Input() set text(text: string) {
    this.fullText = text || '';
    this.isLongText = text.length > this.maxLength;

    if (this.isLongText) {
      this.textSubject.next(text.slice(0, this.maxLength) + '...');
    } else {
      this.textSubject.next(text);
    }
  };

  ngAfterViewChecked(): void {
    if (!this.isHeightSet && this.contentContainer?.nativeElement) {
      this.isHeightSet = true;

      requestAnimationFrame(() => {
        const containerHeight = this.contentContainer.nativeElement.scrollHeight;
        if (containerHeight) {
          this.initialHeight = containerHeight;
          this.cdr.detectChanges();
        }
      });
    }
  }

  toggleShow(): void {
    if (this.isExpanded) {
      timer(300).pipe(
        take(1),
        tap(() => this.textSubject.next(this.fullText.slice(0, this.maxLength) + '...'))
      ).subscribe();
    } else {
      this.textSubject.next(this.fullText);
    }

    this.isExpanded = !this.isExpanded;
  }
}

