<div>
  <ol>
    <li
      class="relative group py-6"
      *ngFor="let item of (isExpanded ? history : history | appLimitArray : itemsToShow : false); let i = index; let last = last"
      [@fadeInOnEnter]
    >
      <ng-container *ngIf="!last">
        <div class="absolute top-6 left-4 w-0.5 h-full -ml-px bg-primary"></div>
      </ng-container>

      <div class="relative flex items-start">
        <div
          class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring-2 ring-inset ring-transparent bg-card ring-primary">

          <ng-container>
            <mat-icon class="icon-size-5 text-current" svgIcon="fa_solid:circle-dot"></mat-icon>
          </ng-container>
        </div>
        <div class="ml-4 flex flex-col" *ngIf="isEdit !== i; else historyEdit">
          <div class="flex flex-col">
            <div class="flex items-center gap-3">
              <h4 class="text-lg font-medium leading-4">
                {{ item.title }}
              </h4>

              <button mat-icon-button *ngIf="isEditable && isEdit === null" (click)="edit(i)">
                <mat-icon class="icon-size-4" svgIcon="heroicons_solid:pencil-alt"></mat-icon>
              </button>
            </div>
            <span class="mt-1.5 text-base leading-4 text-secondary" *ngIf="item?.subTitle">
              {{ item?.subTitle }}
            </span>
            <span class="mt-1.5 text-md leading-4 text-secondary" *ngIf="item?.from">
              {{ item?.from | amDateFormat: 'MMM YYYY' }}
              -
              <ng-container *ngIf="item.forNow; else to">
                Present
              </ng-container>

              <ng-template #to>
                {{ item?.to | amDateFormat: 'MMM YYYY' }}
              </ng-template>
            </span>
          </div>

          <app-show-more
            *ngIf="item?.description"
            class="mt-4 py-4 px-5 rounded-lg bg-gray-200 text-base"
            [text]="item?.description"
          ></app-show-more>
        </div>

        <ng-template #historyEdit [formGroup]="historyForm">
          <div class="ml-4 flex flex-col w-1/2" [@fadeInOnEnter]>
            <mat-form-field class="w-full">
              <mat-label>Title</mat-label>
              <input type="text" placeholder="Title" matInput formControlName="title">
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Subtitle</mat-label>
              <input type="text" placeholder="Subtitle" matInput formControlName="subTitle">
            </mat-form-field>

            <div class="flex gap-6 justify-between items-center">
              <mat-form-field class="w-full">
                <mat-label>Date From</mat-label>
                <input matInput [matDatepicker]="dateFrom" formControlName="from">
                <mat-hint>MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                <mat-datepicker
                  #dateFrom
                  startView="multi-year"
                  (monthSelected)="setMonthAndYear($event, dateFrom, 'from')"
                ></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="w-full">
                <mat-label>Date To</mat-label>
                <input matInput [matDatepicker]="dateTo" formControlName="to">
                <mat-hint>MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                <mat-datepicker
                  #dateTo
                  startView="multi-year"
                  (monthSelected)="setMonthAndYear($event, dateTo, 'to')"
                ></mat-datepicker>
              </mat-form-field>

              <mat-checkbox class="min-w-50 w-full">
                For now
              </mat-checkbox>
            </div>

            <pre>{{historyForm.get('description')?.value | json}}</pre>
<!--            <mat-form-field class="w-full">-->
<!--              <mat-label>Description</mat-label>-->
<!--              <textarea matInput formControlName="description"></textarea>-->
              <quill-editor formControlName="description"></quill-editor>
<!--            </mat-form-field>-->

            <div class="flex justify-end mt-2 w-full gap-3">
              <button
                mat-button
                matTooltip="Cancel"
                (click)="historyForm.reset(); isEdit = null"
              >
                Cancel
              </button>
              <button
                mat-flat-button
                color="primary"
                matTooltip="Save"
                [disabled]="historyForm.invalid || historyForm.pristine"
                (click)="isEdit = null"
              >
                Save
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </li>
  </ol>

  <button
    *ngIf="history?.length > itemsToShow"
    class="flex items-center pl-2 pr-3 py-0.5 gap-1 rounded-full cursor-pointer bg-gray-200 hover:bg-gray-300"
    (click)="toggleView()"
  >
    <mat-icon
      class="icon-size-5"
      [svgIcon]="isExpanded ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'"
    ></mat-icon>
    <span class="text-base font-medium text-secondary">
      {{ !isExpanded ? 'More' : 'Less' }}
    </span>
  </button>
</div>
