import { Component, Input } from '@angular/core';
import moment from 'moment';

import { CandidateHistory, CandidateHistoryEventType } from '../../../types/candidate-history';
import { activityData } from './activity.types';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html'
})
export class ActivityComponent {
  @Input() activities!: CandidateHistory[];
  public activityData = activityData;
  public activityDateTypes = CandidateHistoryEventType;

  public isSameDay(current: Date, compare: Date): boolean {
    return moment(current, moment.ISO_8601).isSame(moment(compare, moment.ISO_8601), 'day');
  }

  public getRelativeFormat(date: Date): string {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');

    if (moment(date, moment.ISO_8601).isSame(today, 'day')) {
      return 'Today';
    }

    if (moment(date, moment.ISO_8601).isSame(yesterday, 'day')) {
      return 'Yesterday';
    }

    return moment(date, moment.ISO_8601).fromNow();
  }
}
