<div>
  <button
    class="h-6 min-h-6 px-2 rounded-full bg-hover"
    mat-button
    [matMenuTriggerFor]="conversionMenu"
  >
    <span class="font-medium text-sm text-secondary">
      {{ feedback?.flowStep?.step?.title }}
    </span>
  </button>

  <mat-menu #conversionMenu="matMenu" class="[&>*]:max-h-80">
    <button
      *ngFor="let flow of feedback?.job?.flow"
      mat-menu-item
      (click)="updateStep.emit(flow)"
      [disabled]="flow?.step.id === feedback?.flowStep?.step.id || !flow?.step?.manualAssignmentAllowed"
    >
      {{ flow?.step?.title }}
    </button>
  </mat-menu>
</div>
