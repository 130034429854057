import { User } from './user';

export enum CandidateHistoryEventType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  TAG_ADDED = 'TAG_ADDED',
  TAG_REMOVED = 'TAG_REMOVED',
  APPLIED_TO_JOB = 'APPLIED_TO_JOB',
  REMOVED_FROM_JOB = 'REMOVED_FROM_JOB',
  JOB_STAGE_CHANGED = 'JOB_STAGE_CHANGED',
  COMMENT_ADDED = 'COMMENT_ADDED',
  FEEDBACK_ADDED = 'FEEDBACK_ADDED',
  STATUS_CHANGED = 'STATUS_CHANGED',
  FILE_ADDED = 'FILE_ADDED',
  FILE_REMOVED = 'FILE_REMOVED',
  RESPONSIBLE_ADDED = 'RESPONSIBLE_ADDED',
  RESPONSIBLE_REMOVED = 'RESPONSIBLE_REMOVED',
}

interface Feedback {
  id: string;
  feedback: string;
  title?: string;
}

interface Comment {
  comment: string;
}

interface Status {
  from: string | null;
  to: string | null;
}

interface Stage {
  from: string | null;
  to: string | null;
  comment?: string;
}

interface Company {
  id?: string;
  name?: string;
  slug?: string;
}

interface Job {
  id: string;
  title: string;
  company: Company;
  comment?: string;
  slug?: string;
  stage?: Stage;
}

interface Tag {
  name: string;
}

interface File {
  fileName: string;
  id: string;
}

export interface HistoryDetails {
  comment?: Comment;
  feedback?: Feedback;
  status?: Status;
  job?: Job;
  tag?: Tag;
  responsible?: Partial<User>;
  file?: File;
}

export interface CandidateHistory {
  createdAt: Date;
  createdBy: User;
  details: HistoryDetails;
  eventType: CandidateHistoryEventType;
  id: string;
  isEditable: boolean;
  isDeletable: boolean;
  updatedAt: Date;
}
