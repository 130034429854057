<mat-form-field class="w-full">
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    [placeholder]="label"
    [id]="id"
    matInput
    [formControl]="locationFilterFC"
    [matAutocomplete]="auto"
  >
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of locationOptions$ | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
