<mat-form-field appearance="fill" class="sort-by-select w-full">
  <mat-select value="createdAtAsk" (valueChange)="sortChanged($event)">
    <ng-container *ngFor="let field of fields | keyvalue">
      <mat-option [value]="{active: field.key, direction: 'ASC'}">
        {{ field.value.label }} ({{ field.value.asc }})
      </mat-option>

      <mat-option [value]="{active: field.key, direction: 'DESC'}">
        {{ field.value.label }} ({{ field.value.desc }})
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
