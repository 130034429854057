<div>
  <ol>
    <ng-container *ngFor="let activity of activities; let i = index; let first = first; let last = last;">
      <ng-container *ngIf="first || !isSameDay(activity.createdAt, activities[i - 1].createdAt)">
        <li class="relative flex">
          <div
            class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
            {{ getRelativeFormat(activity.createdAt) }}
          </div>
        </li>
      </ng-container>

      <li class="relative flex py-7">
        <ng-container *ngIf="!last && isSameDay(activity.createdAt, activities[i + 1].createdAt)">
          <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
        </ng-container>

        <div class="relative flex flex-auto">
          <ng-container *ngIf="activityData[activity.eventType]?.icon">
            <div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
              <mat-icon
                class="icon-size-5 text-white"
                [svgIcon]="activityData[activity.eventType]?.icon">
              </mat-icon>
            </div>
          </ng-container>

          <div class="flex flex-col flex-auto items-start">
            <ng-container *ngIf="activityData[activity.eventType]?.description">
              <div class="flex flex-row items-center gap-1">
                <ng-container [ngSwitch]="activity.eventType">
                  <ng-container
                    *ngSwitchCase="activityDateTypes.TAG_ADDED"
                    [ngTemplateOutlet]="tagsHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>

                  <ng-container
                    *ngSwitchCase="activityDateTypes.TAG_REMOVED"
                    [ngTemplateOutlet]="tagsHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>

                  <ng-container
                    *ngSwitchCase="activityDateTypes.STATUS_CHANGED"
                    [ngTemplateOutlet]="statusChangedHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>

                  <ng-container
                    *ngSwitchCase="activityDateTypes.CREATED"
                    [ngTemplateOutlet]="defaultHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>

                  <ng-container
                    *ngSwitchCase="activityDateTypes.UPDATED"
                    [ngTemplateOutlet]="defaultHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>

                  <ng-container
                    *ngSwitchCase="activityDateTypes.RESPONSIBLE_ADDED"
                    [ngTemplateOutlet]="responsibleHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>

                  <ng-container
                    *ngSwitchCase="activityDateTypes.RESPONSIBLE_REMOVED"
                    [ngTemplateOutlet]="responsibleHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>

                  <ng-container
                    *ngSwitchCase="activityDateTypes.APPLIED_TO_JOB"
                    [ngTemplateOutlet]="vacancyHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>

                  <ng-container
                    *ngSwitchCase="activityDateTypes.JOB_STAGE_CHANGED"
                    [ngTemplateOutlet]="vacancyHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>

                  <ng-container
                    *ngSwitchCase="activityDateTypes.REMOVED_FROM_JOB"
                    [ngTemplateOutlet]="vacancyHeader"
                    [ngTemplateOutletContext]="{activity}"
                  ></ng-container>
                </ng-container>

                <ng-container *ngIf="activity.createdBy">
                  by <strong>{{ activity.createdBy.firstName }} {{ activity.createdBy.lastName }}</strong>
                </ng-container>
              </div>
            </ng-container>

            <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-md leading-5">
              <div class="text-secondary">
                {{ activity.createdAt | date:'MMM dd, HH:mm' }}
              </div>

              <ng-container
                *ngIf="(activity.eventType === activityDateTypes.APPLIED_TO_JOB ||
                  activity.eventType === activityDateTypes.JOB_STAGE_CHANGED ||
                  activity.eventType === activityDateTypes.REMOVED_FROM_JOB) && activity.details.job?.company"
              >
                <div class="hidden sm:block">&bull;</div>

                <ng-container>
                  <a class="cursor-pointer text-primary">
                    {{ activity.details.job?.company?.name }}
                  </a>
                </ng-container>
              </ng-container>
            </div>

            <ng-container *ngIf="activity.details">
              <ng-container
                *ngIf="activity.eventType === activityDateTypes.FEEDBACK_ADDED || activity.eventType === activityDateTypes.COMMENT_ADDED">
                <div class="mt-4 py-4 px-5 rounded-lg bg-gray-200 dark:bg-gray-800">
                  <h2
                    *ngIf="activity?.details?.feedback?.title"
                    class="mb-2 text-base font-bold"
                  >
                    {{ activity?.details?.feedback?.title }}
                  </h2>

                  <div [innerHTML]="activity.details?.comment?.comment || activity.details?.feedback?.feedback"></div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </li>
    </ng-container>
  </ol>
</div>


<ng-template #tagsHeader let-activity="activity">
  <div class="flex flex-row items-center gap-2">
    <span
      class="text-[9px] font-medium whitespace-nowrap py-1 px-3 rounded-full leading-normal text-gray-800 bg-gray-200"
      [class.line-through]="activity.eventType === activityDateTypes.TAG_REMOVED"
    >
      {{ activity.details.tag.name }}
    </span>

    <span>{{ activityData[activity.eventType]?.description }}</span>
  </div>
</ng-template>

<ng-template #statusChangedHeader let-activity="activity">
  <span>{{ activityData[activity.eventType]?.description }}</span>

  <ng-container *ngIf="activity.details.status.from">
    <span>from: </span>
    <span
      class="text-xs font-medium whitespace-nowrap py-1 px-3 rounded-full leading-normal bg-red-200 text-red-800 line-through">
      {{ activity.details.status.from }}
    </span>
  </ng-container>

  <ng-container *ngIf="activity.details.status.to">
    <span>to: </span>
    <span
      class="text-xs font-medium whitespace-nowrap py-1 px-3 rounded-full leading-normal bg-green-200 text-green-800">
      {{ activity.details.status.to }}
    </span>
  </ng-container>
</ng-template>

<ng-template #responsibleHeader let-activity="activity">
  <a
    class="cursor-default text-primary text-md"
    [class.line-through]="activity.eventType === activityDateTypes.RESPONSIBLE_REMOVED"
  >
    <ng-container
      *ngIf="activity.details?.responsible?.firstName && activity.details?.responsible?.lastName; else responsibleEmail">
      {{ activity.details.responsible.firstName }} {{ activity.details.responsible.lastName }}
    </ng-container>

    <ng-template #responsibleEmail>
      {{ activity.details.responsible?.email }}
    </ng-template>
  </a>

  <span>{{ activityData[activity.eventType]?.description }}</span>
</ng-template>

<ng-template #defaultHeader let-activity="activity">
  <span>{{ activityData[activity.eventType]?.description }}</span>
</ng-template>


<ng-template #vacancyHeader let-activity="activity">
  <span>{{ activityData[activity.eventType]?.description }}</span>

  <a class="cursor-pointer text-primary" [routerLink]="'../../jobs/' + activity.details.job.slug">
    {{ activity.details.job.title }}
  </a>

  <ng-container *ngIf="activity.details?.job?.stage?.from">
    <span>from: </span>
    <span class="text-xs font-medium whitespace-nowrap py-1 px-3 rounded-full leading-normal bg-red-200 text-red-800 line-through">
      {{ activity.details.job.stage.from }}
    </span>
  </ng-container>

  <ng-container *ngIf="activity.details?.job?.stage?.to">
    <span>to: </span>
    <span class="text-xs font-medium whitespace-nowrap py-1 px-3 rounded-full leading-normal bg-green-200 text-green-800">
      {{ activity.details.job.stage.to }}
    </span>
  </ng-container>
</ng-template>
