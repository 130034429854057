import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapJoin',
})
export class MapJoinPipe implements PipeTransform {
  transform<T>(value: T[], propertyPath: string, delimiter = ', '): string {
    if (!Array.isArray(value) || !propertyPath) {
      return '';
    }

    return value
      .map(item => this.getNestedProperty(item, propertyPath))
      .filter(val => val !== undefined && val !== null)
      .join(delimiter);
  }

  private getNestedProperty(obj: any, path: string): any {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
  }
}
