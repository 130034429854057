import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { UserService } from '../../../modules/users/services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appUser'
})
export class UserComponent implements OnInit, OnDestroy {
  @Input() showAvatar: boolean = true;
  public user: any;

  private unsubscribe$ = new Subject<any>();

  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }


  ngOnInit(): void {
    this.userService.getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => this.user = user);
  }

  public updateUserStatus(status: string): void {
    if (!this.user) {
      return;
    }
  }

  public signOut(): void {
    this.router.navigate(['/auth/sign-out']);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
