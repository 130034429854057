import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment/moment';
import { Moment } from 'moment';

import { History } from './history.types';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  animations: [
    fadeInOnEnterAnimation()
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class HistoryComponent {
  @Input() history!: History[];
  @Input() itemsToShow = 3;
  @Input() isEditable = true;

  @Output() editHistory = new EventEmitter<History>();
  @Output() deleteHistory = new EventEmitter<string>();

  public isExpanded = false;
  public isEdit: number | null = null;
  public historyForm = this.formBuilder.group({
    title: ['', [Validators.required]],
    subTitle: [''],
    from: [moment()],
    to: [moment()],
    forNow: false,
    description: ['']
  });

  constructor(private readonly formBuilder: FormBuilder) {}

  public setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>, controlName: string): void {
    const control = this.historyForm.get(controlName);
    control?.value?.month(normalizedMonthAndYear.month());
    control?.value?.year(normalizedMonthAndYear.year());
    control.setValue(control.value);
    datepicker.close();
  }

  public toggleView(): void {
    this.isExpanded = !this.isExpanded;
  }

  public edit(index: number): void {
    this.isEdit = index;
    this.historyForm.patchValue(this.history[index]);
  }
}
