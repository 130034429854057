import { Component, Input } from '@angular/core';

import { sourceTypes } from './candidate-source.types';
import { VocabulariesMap } from '../../../types';

@Component({
  selector: 'app-candidate-source',
  templateUrl: './candidate-source.component.html',
})
export class CandidateSourceComponent {
  @Input() vocabularies: VocabulariesMap;
  @Input() source: string;
  @Input() size = 3;
  @Input() withLabel = false;
  @Input() showTooltip = true;

  public sourceTypes = sourceTypes;
}
