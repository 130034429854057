import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { IAlertType } from '../components/alert/alert.types';
import { UtilsService } from './utils.service';

export interface INotification {
  type: IAlertType;
  dismissible: boolean;
  title: string;
  message: string;
  autoClose?: number;
  id?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private notificationsList: INotification[] = [];
  private notifications$ = new Subject<INotification[]>();

  constructor(
    private utilsService: UtilsService,
    private http: HttpClient,
  ) {
  }

  get notifications(): Observable<INotification[]> {
    return this.notifications$.asObservable();
  }

  public showNotification(notification: INotification): any {
    const id = this.utilsService.randomId();
    const notificationObject = {...notification, id};

    this.notificationsList.push(notificationObject);
    this.notifications$.next(this.notificationsList);

    if (notification.autoClose) {
      setTimeout(() => {
        const notificationToRemove = this.notificationsList.findIndex((n: any) => n.id === notification.id);
        this.notificationsList.splice(notificationToRemove, 1);
        this.notifications$.next(this.notificationsList);
      }, notification.autoClose);
    }
  }

  public downloadChromeExtension(): any {
    return this.http.get(`${environment.api}/main/download-chrome-extension`, {responseType: 'blob', observe: 'response'});
  }

  public saveFile(blob: Blob, fileName: string): void {
    const objectURL = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = objectURL;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(objectURL);
  }

  public clearCache(): Observable<any> {
    return this.http.get(`${environment.api}/main/clear-cache`);
  }
}
