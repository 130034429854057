import { BlockScrollStrategy, Overlay, OverlayModule } from '@angular/cdk/overlay';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';
import { CookieService } from 'ngx-cookie-service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';

import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';

import { NotificationsComponent } from './components/notifications/notifications.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { FullscreenComponent } from './components/fullscreen/fullscreen.component';
import { QuickChatComponent } from './components/quick-chat/quick-chat.component';
import { ShortcutsComponent } from './components/shortcuts/shortcuts.component';
import { MessagesComponent } from './components/messages/messages.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SearchComponent } from './components/search/search.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { TeamsComponent } from './components/teams/teams.component';
import { AlertComponent } from './components/alert/alert.component';
import { UserComponent } from './components/user/user.component';
import { CardComponent } from './components/card/card.component';
import { TagsComponent } from './components/tags/tags.component';

import { IconsModule } from './modules/icons/icons.module';

import { ConfirmationDialogComponent } from './dialogs/confirm-dialog/dialog.component';
import { ScrollResetDirective } from './directives/scroll-reset/scroll-reset.directive';
import { ScrollbarDirective } from './directives/scrollbar/scrollbar.directive';
import { ConfirmationService } from './services/confirmation.service';
import { MaterialModule } from './modules/material/material.module';
import { FirstLastPipe } from './pipes/first-last.pipe';
import { FindByKeyPipe } from './pipes/find-by-key.pipe';

const SHARED_COMPONENTS = [
  SplashScreenComponent,
  SettingsComponent,
  QuickChatComponent,
  MessagesComponent,
  NotificationsComponent,
  SearchComponent,
  ShortcutsComponent,
  UserComponent,
  ScrollResetDirective,
  ScrollbarDirective,
  DrawerComponent,
  FullscreenComponent,
  LoadingBarComponent,
  AlertComponent,
  CardComponent,
  TeamsComponent,
  ConfirmationDialogComponent,
  FirstLastPipe,
  TagsComponent,
  FindByKeyPipe
];

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    RouterModule,
    IconsModule,
    HttpClientModule,
    MomentModule,
    NgPipesModule,
    NgxMaskModule,
    ClipboardModule,
  ],
  exports: [
    ...SHARED_COMPONENTS,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    MomentModule,
    NgPipesModule,
    NgxMaskModule,
    ClipboardModule,
  ],
  declarations: [...SHARED_COMPONENTS],
  providers: [{
    provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
    useFactory: (overlay: Overlay) => (): BlockScrollStrategy => overlay.scrollStrategies.block(),
    deps: [Overlay]
  }, ConfirmationService, CookieService]
})
export class SharedModule {
}
