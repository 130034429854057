<mat-form-field class="w-full">
  <mat-label>{{label}}</mat-label>

  <mat-select [multiple]="multiple" [formControl]="selectFC">
    <mat-select-trigger *ngIf="showChips">
      <mat-chip-list class="flex mx-0 my-[7px]">
        <ng-container *ngIf="maxChips; else allChips">
          <mat-chip
            *ngFor="let option of selectFC.value | appLimitArray: maxChips; let i = index;"
            [removable]="true"
            (removed)="removeTag(option)"
          >
            <ng-container *ngIf="i < maxChips">
              {{ selectOptionsMap.get(option).title }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </ng-container>

            <ng-container *ngIf="i >= maxChips">
              {{ option }}
            </ng-container>
          </mat-chip>
        </ng-container>

        <ng-template #allChips>
          <mat-chip
            *ngFor="let option of selectFC.value; let i = index;"
            [removable]="true"
            (removed)="removeTag(option)"
          >
            {{ selectOptionsMap.get(option).title }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </ng-template>
      </mat-chip-list>
    </mat-select-trigger>

    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="Search"
        noEntriesFoundLabel="Not found..."
        [formControl]="selectFilterFC"
      ></ngx-mat-select-search>
    </mat-option>

    <mat-option
      *ngFor="let option of options$ | async"
      [value]="option.id"
    >
      {{ option.title }}
    </mat-option>
  </mat-select>
</mat-form-field>
