<ng-container *ngIf="size === 'large'; else small">
  <mat-icon
    *ngIf="job?.status === 'open'"
    class="icon-size-5 text-green-500"
    svgIcon="fa_solid:circle-check"
    [matTooltip]="getJobStatusTooltip(job)"
  ></mat-icon>

  <mat-icon
    *ngIf="job?.status === 'closed'"
    class="icon-size-5 text-red-500"
    svgIcon="fa_solid:circle-xmark"
    [matTooltip]="getJobStatusTooltip(job)"
  ></mat-icon>

  <mat-icon
    *ngIf="job?.status === 'draft'"
    class="icon-size-5 text-amber-500"
    svgIcon="fa_solid:file"
    [matTooltip]="getJobStatusTooltip(job)"
  ></mat-icon>
</ng-container>


<ng-template #small>
  <span
    class="w-2 h-2 rounded-full"
    [matTooltip]="getJobStatusTooltip(job)"
    [ngClass]="{
      'bg-red-500': job?.status === 'closed',
      'bg-green-500': job?.status === 'open',
      'bg-amber-500': job?.status === 'draft'
    }"
  ></span>
</ng-template>
