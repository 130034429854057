import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { SortSelectOption } from './sort-select.types';

@Component({
  selector: 'app-sort-select',
  templateUrl: './sort-select.component.html',
  styleUrls: [
    './sort-select.component.scss'
  ]
})
export class SortSelectComponent {
  @Input() fields: Record<string, SortSelectOption>;

  @Output() sortChange: EventEmitter<Sort> = new EventEmitter();

  public direction: string;
  public active: string;

  public sortChanged(event: Sort): void {
    this.direction = event.direction;
    this.active = event.active;

    this.sortChange.emit(event);
  }
}
