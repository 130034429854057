import { MatPaginator } from '@angular/material/paginator';
import { IsActiveMatchOptions } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { Injectable } from '@angular/core';

import { SortSelectComponent } from '../components/sort-select/sort-select.component';
import { SEARCH_ORDER, SearchQueryMeta } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() {
  }

  get exactMatchOptions(): IsActiveMatchOptions {
    return {
      paths: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'exact'
    };
  }

  get subsetMatchOptions(): IsActiveMatchOptions {
    return {
      paths: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'subset'
    };
  }

  public randomId(): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let name = '';

    for (let i = 0; i < 10; i++) {
      name += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return name;
  }

  public getPaginationOptions(paginator: MatPaginator, sort: MatSort | SortSelectComponent, q?: string): SearchQueryMeta {
    const options: SearchQueryMeta = {
      page: typeof paginator?.pageIndex !== 'undefined' ? paginator?.pageIndex + 1 : 1,
      take: paginator?.pageSize || 10,
    };

    if (sort?.direction) {
      options.order = sort.direction.toUpperCase() as SEARCH_ORDER;
    }

    if (sort?.active) {
      options.orderBy = sort.active;
    }

    if (q) {
      options.q = q;
    }

    return options;
  }

  public readAsDataURL(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (): void => {
        resolve(reader.result);
      };

      reader.onerror = (e): void => {
        reject(e);
      };

      reader.readAsDataURL(file);
    });
  }
}
