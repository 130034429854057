<app-splash-screen *ngIf="showSplashScreen"></app-splash-screen>

<div class="layout relative">
  <div class="absolute alerts" *ngIf="notifications$ | async as notifications">
    <ng-container *ngFor="let notification of notifications">
      <app-alert
        [type]="notification.type"
        [dismissible]="notification.dismissible"
        class="mb-5"
      >
        <span appAlertTitle>{{notification.title}}</span>
        {{notification.message}}
      </app-alert>
    </ng-container>
  </div>

  <app-loading-bar></app-loading-bar>

  <ng-container *ngIf="navigation$ | async; else emptyLayout">
    <app-navigation
      [mode]="isScreenSmall ? 'over' : 'side'"
      [opened]="navigationOpened"
      [name]="'mainNavigation'"
      class="dark bg-gray-900 print:hidden"
    >
      <ng-container appNavigationContentHeader>
        <div class="flex items-center h-20 p-6 pb-0">
          <img
            alt="Logo image"
            class="dark:hidden w-30"
            src="assets/images/logo/logo-black.svg">
          <img
            alt="Logo image"
            class="hidden dark:flex w-30"
            src="assets/images/logo/logo-white.svg">
        </div>
      </ng-container>
    </app-navigation>

    <div class="flex flex-col flex-auto w-full min-w-0 h-screen">
      <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <button
          (click)="toggleNavigation()"
          mat-icon-button
        >
          <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
          <app-fullscreen class="hidden md:block"></app-fullscreen>
<!--          <app-search [appearance]="'bar'"></app-search>-->
          <app-shortcuts></app-shortcuts>
          <app-messages></app-messages>
          <app-notifications></app-notifications>
          <button mat-icon-button matTooltip="Download Chrome Extension" (click)="downloadChromeExtension()">
            <mat-icon svgIcon="extension"></mat-icon>
          </button>
<!--          <button-->
<!--            (click)="appSettings.toggle()"-->
<!--            mat-icon-button>-->
<!--            <mat-icon [svgIcon]="'heroicons_solid:cog'"></mat-icon>-->
<!--          </button>-->

<!--          <div class="lg:hidden">-->
<!--            <button-->
<!--              (click)="quickChat.toggle()"-->
<!--              class="lg:hidden"-->
<!--              mat-icon-button>-->
<!--              <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>-->
<!--            </button>-->
<!--          </div>-->
          <app-user></app-user>
        </div>
      </div>

      <div class="flex flex-col flex-auto overflow-auto">
        <router-outlet *ngIf="true"></router-outlet>
      </div>
    </div>

<!--    <app-quick-chat #quickChat="quickChat"></app-quick-chat>-->
<!--    <app-settings #appSettings="appSettings"></app-settings>-->
  </ng-container>

  <ng-template #emptyLayout>
    <div class="flex items-center justify-center w-full">
      <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
      </div>
    </div>
  </ng-template>
</div>


