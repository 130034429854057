<!--<pre>{{tagsFA.value | json}}</pre>-->
<!--<pre>{{ selectedTags | json }}</pre>-->

<div class="flex flex-wrap items-center">
  <ng-container *ngIf="selectedTags.length">
    <ng-container *ngFor="let tag of selectedTags; trackBy: trackByFn">
      <div
        class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
        <span class="text-md font-medium whitespace-nowrap">{{ tag.title }}</span>
      </div>
    </ng-container>
  </ng-container>

  <div
    class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
    (click)="openTagsPanel()"
    #tagsPanelOrigin
  >
    <ng-container *ngIf="selectedTags.length">
      <mat-icon class="icon-size-5" svgIcon="heroicons_solid:pencil-alt"></mat-icon>
      <span class="ml-1.5 text-md font-medium whitespace-nowrap">Edit</span>
    </ng-container>

    <ng-container *ngIf="!selectedTags.length">
      <mat-icon class="icon-size-5" svgIcon="heroicons_solid:plus-circle"></mat-icon>
      <span class="ml-1.5 text-md font-medium whitespace-nowrap">Add</span>
    </ng-container>

    <ng-template #tagsPanel>
      <div class="w-60 rounded border shadow-md bg-card">
        <div class="flex items-center m-3 mr-2">
          <div class="flex items-center">
            <mat-icon class="icon-size-5" svgIcon="heroicons_solid:search"></mat-icon>
            <div class="ml-2">
              <input
                class="w-full min-w-0 py-1 border-0"
                type="text"
                placeholder="Enter tag name"
                [formControl]="searchFC"
                [maxLength]="30"
                #newTagInput
              >
            </div>
          </div>

          <button
            class="ml-1"
            mat-icon-button
            (click)="toggleTagsEditMode()"
          >
            <mat-icon
              *ngIf="!tagsEditMode"
              class="icon-size-5"
              svgIcon="heroicons_solid:pencil-alt"
            ></mat-icon>
            <mat-icon
              *ngIf="tagsEditMode"
              class="icon-size-5"
              svgIcon="heroicons_solid:check"
            ></mat-icon>
          </button>
        </div>

        <div class="flex flex-col max-h-64 py-2 border-t overflow-y-auto" [formGroup]="form">
          <ng-container *ngIf="!tagsEditMode">
<!--            <pre>{{tagsFA.controls | json}}</pre>-->
<!--            <ng-container *ngFor="let tag of tagsFA.controls; trackBy: trackByFn; let i = index" formArrayName="tags">-->
            <ng-container *ngFor="let tag of tags$ | async; let i = index" formArrayName="tags">
              <div
                [formGroup]="tag"
                class="flex items-center h-10 min-h-10 px-4 cursor-pointer hover:bg-hover"
                matRipple
                (click)="toggleContactTag(tag)"
              >
                <mat-checkbox
                  class="flex items-center h-10 min-h-10 pointer-events-none"
                  formControlName="selected"
                  color="primary"
                  [disableRipple]="true"
                >
                  <div class="ml-1">{{ tag.get('title').value }}</div>
                </mat-checkbox>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="tagsEditMode">
            <div class="py-2 space-y-2">
              <ng-container *ngFor="let tag of filteredTags; trackBy: trackByFn">
                <div class="flex items-center">
                  <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript w-full mx-4">
                    <input
                      matInput
                      [value]="tag.title"
                      (input)="updateTagTitle(tag, $event)">
                    <button
                      mat-icon-button
                      (click)="deleteTag(tag)"
                      matSuffix>
                      <mat-icon class="icon-size-5 ml-2" svgIcon="heroicons_solid:trash"></mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <div
            class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer hover:bg-hover"
            *ngIf="shouldShowCreateTagButton(newTagInput.value)"
            (click)="createTag(newTagInput.value); newTagInput.value = ''"
            matRipple
          >
            <mat-icon class="mr-2 icon-size-5" svgIcon="heroicons_solid:plus-circle"></mat-icon>
            <div class="break-all">Create "<b>{{ newTagInput.value }}</b>"</div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
