import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import stc from 'string-to-color';

import { User } from '../../../types';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {
  @Input() user: User;
  @Input() altProperty?: string;
  @Input() showTooltip = true;

  public getColor(value: string): string | number {
    return stc(value.toLowerCase());
  }

  public getTooltipText(): string {
    return this.user.firstName && this.user.lastName
      ? this.user.firstName + ' ' + this.user.lastName
      : this.altProperty && this.user[this.altProperty]
        ? this.user[this.altProperty]
        : '';
  }
}
