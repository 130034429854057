import {
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  forwardRef,
  Input, Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { debounceTime, distinctUntilChanged, map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsComponent),
      multi: true
    }
  ]
})
export class TagsComponent implements ControlValueAccessor {
  @Output() addTag = new EventEmitter();
  @Output() editTag = new EventEmitter();
  // @Output() deleteTag = new EventEmitter();

  @ViewChild('tagsPanel') private tagsPanel: TemplateRef<any>;
  @ViewChild('tagsPanelOrigin') private tagsPanelOrigin: ElementRef;

  public tagsEditMode = false;
  public filteredTags: any[];
  public searchFC = this.formBuilder.control('');
  // public selectedTags: any[];
  public form = this.formBuilder.group({
    tags: this.formBuilder.array([])
  });
  // public tagsFA = this.formBuilder.array<FormGroup>([]);

  public tags$: Observable<FormGroup[]> = this.searchFC.valueChanges.pipe(
    startWith(''),
    // debounceTime(200),
    distinctUntilChanged(),
    map(val =>
      this.tagsFA.controls.filter((group: FormGroup) =>
        group.get('title').value
          .toLowerCase()
          .includes(val.toLowerCase())
      ) as FormGroup[]
    )
  );

  private allTags: any[];
  private tagsPanelOverlayRef: OverlayRef;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly formBuilder: FormBuilder,
    private readonly cdRef: ChangeDetectorRef,
    private readonly renderer2: Renderer2,
    private readonly overlay: Overlay,
  ) {
  }

  get tagsFA(): FormArray {
    return this.form.get('tags') as FormArray;
  }

  get selectedTags(): any[] {
    const selectedIds = this.tagsFA.controls.map((tagFG: FormGroup) => {
      if (tagFG.get('selected').value) {
        return tagFG.get('id').value;
      }
      return null;
    }).filter(id => id !== null);

    return this.allTags.filter(({id}) => selectedIds.includes(id));
  }

  get tags(): any[] {
    return this.allTags;
  }

  @Input() set tags(tags: any[]) {
    this.allTags = tags;

    for (const tag of tags) {
      this.tagsFA.push(this.buildTagFG(tag.id, tag.title));
    }
  }

  public filterTags(event: any): void {
    const value = event.target.value.toLowerCase();

    this.filteredTags = this.tags.filter(tag => tag.title.toLowerCase().includes(value));
  }

  public filterTagsInputKeyDown(event: any): void {
    if (event.key !== 'Enter') {
      return;
    }

    if (this.filteredTags.length === 0) {
      this.createTag(event.target.value);

      event.target.value = '';

      return;
    }

    const tag = this.filteredTags[0];
    const isTagApplied = this.tags.find((id: any) => id === tag.id);

    if (isTagApplied) {
      // this.removeTagFromContact(tag);
    } else {
      // this.addTagToContact(tag);
    }
  }

  public openTagsPanel(): void {
    this.tagsPanelOverlayRef = this.overlay.create({
      backdropClass: '',
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.tagsPanelOrigin.nativeElement)
        .withFlexibleDimensions(true)
        .withViewportMargin(64)
        .withLockedPosition(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          }
        ])
    });

    this.tagsPanelOverlayRef.attachments().subscribe(() => {

      // Add a class to the origin
      this.renderer2.addClass(this.tagsPanelOrigin.nativeElement, 'panel-opened');

      // Focus to the search input once the overlay has been attached
      this.tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
    });

    const templatePortal = new TemplatePortal(this.tagsPanel, this.viewContainerRef);

    this.tagsPanelOverlayRef.attach(templatePortal);

    this.tagsPanelOverlayRef.backdropClick().subscribe(() => {
      this.renderer2.removeClass(this.tagsPanelOrigin.nativeElement, 'panel-opened');

      if (this.tagsPanelOverlayRef && this.tagsPanelOverlayRef.hasAttached()) {
        this.tagsPanelOverlayRef.detach();

        this.filteredTags = this.tags;

        this.tagsEditMode = false;
      }

      if (templatePortal && templatePortal.isAttached) {
        templatePortal.detach();
      }
    });
  }

  public toggleTagsEditMode(): void {
    this.tagsEditMode = !this.tagsEditMode;
  }

  createTag(title: string): void {
    const tag = {
      title
    };

    // this._contactsService.createTag(tag)
    //   .subscribe((response) => {
    //
    //     this.addTagToContact(response);
    //   });
  }

  updateTagTitle(tag: any, event: any): void {
    tag.title = event.target.value;

    // this._contactsService.updateTag(tag.id, tag)
    //   .pipe(debounceTime(300))
    //   .subscribe();

    this.cdRef.markForCheck();
  }

  deleteTag(tag: any): void {
    // this._contactsService.deleteTag(tag.id).subscribe();

    this.cdRef.markForCheck();
  }

  // public toggleContactTag(index: number): void {
  //   const tagSelectedFC = this.tagsFA.at(index).get('selected');
  //   tagSelectedFC.patchValue(!tagSelectedFC.value);
  // }

  public toggleContactTag(tagFG: FormGroup): void {
    // console.log(tagFG, 'ss');
    const tagSelectedFC = tagFG.get('selected');
    tagSelectedFC.patchValue(!tagSelectedFC.value);
  }

  public shouldShowCreateTagButton(inputValue: string): boolean {
    return !!!(inputValue === '' || this.tags.findIndex(tag => tag.title.toLowerCase() === inputValue.toLowerCase()) > -1);
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(tagIds: number[]): void {
    for (const tagFG of this.tagsFA.controls) {
      if (tagIds.includes(tagFG.get('id').value)) {
        tagFG.get('selected').setValue(true);
      }
    }
  }

  private buildTagFG(id: any, title: string, selected: boolean = false): FormGroup {
    return this.formBuilder.group({
      id,
      title,
      selected
    });
  }
}
