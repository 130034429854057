import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
    AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { slideInDownOnEnterAnimation } from 'angular-animations';
import { Subject, takeUntil } from 'rxjs';

import { CustomValidators } from '../../validators/validators';
import { ContactType, contactTypes } from './contacts.types';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactsComponent),
      multi: true,
    },
  ],
  animations: [
    slideInDownOnEnterAnimation()
  ]
})
export class ContactsComponent implements ControlValueAccessor, OnDestroy, Validator {
  @Input() showButtons = true;
  @Output() editableChange = new EventEmitter();
  @Output() saveContacts = new EventEmitter();

  public form = this.formBuilder.group({
    contacts: this.formBuilder.array([])
  });
  public contactTypes = contactTypes;
  private isEditable!: boolean;
  private unsubscribe$ = new Subject();
  private contacts: any[];

  constructor(
    private readonly formBuilder: FormBuilder,
  ) {
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(({contacts}) => {
      this.setValue(contacts);
    });
  }

  get contactsFA(): FormArray {
    return this.form.get('contacts') as FormArray;
  }

  get editable(): boolean {
    return this.isEditable;
  }

  @Input() set editable(editable: boolean) {
    this.isEditable = editable;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public writeValue(contacts: any[]): void {
    this.contacts = contacts;

    for (const contact of contacts) {
      this.contactsFA.push(this.buildContactFG(contact.type, contact.value, contact.id));
    }
  }

  public validate(control: AbstractControl<any, any>): ValidationErrors {
    if (control.hasValidator(Validators.required)) {
      this.contactsFA.setValidators(Validators.required);
    }

    return this.form.invalid ? {contactsInvalid: true} : null;
  }

  public addContact(): void {
    this.contactsFA.push(this.buildContactFG('phone', ''));
  }

  public cancelEdit(): void {
    this.editableChange.emit(false);
    this.contactsFA.clear();

    for (const contact of this.contacts) {
      this.contactsFA.push(this.buildContactFG(contact.type, contact.value, contact.id));
    }
  }

  public save(): void {
    this.editableChange.emit(false);
    this.setValue(this.contactsFA.value);
    this.saveContacts.emit(this.contactsFA.value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private buildContactFG(type: ContactType, value: string, id: string = null): FormGroup {
    const validators = this.getContactValidators(type);

    const contactFG = this.formBuilder.group({
      id,
      type,
      value: [value, validators]
    });

    contactFG.get('type').valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        const valueFC = contactFG.get('value');
        valueFC.removeValidators(validators);
        valueFC.setValidators(this.getContactValidators(val));
        valueFC.updateValueAndValidity();
      });

    return contactFG;
  }

  private getContactValidators(type: ContactType): ValidatorFn | ValidatorFn[] {
    const validators = [Validators.required];

    const typeValidator = this.contactTypes[type].validator;

    if (typeValidator) {
      validators.push(CustomValidators.createValidator(this.contactTypes[type].validator));
    }

    return validators;
  }

  private setValue(value: any): void {
    this.onChange(value);
    this.onTouched();
  }

  private onTouched = (): any => {};
  private onChange = (m: any): any => {};
}
