<div
  *ngIf="vocabularies?.candidateSource?.terms[source]?.machineName as machineName"
  class="flex flex-row gap-2 cursor-default"
  [matTooltip]="sourceTypes[machineName]?.label"
  matTooltipPosition="below"
  [matTooltipDisabled]="!showTooltip"
>
  <mat-icon
    [class]="'icon-size-' + size"
    [svgIcon]="sourceTypes[machineName]?.icon"
  ></mat-icon>

  <span *ngIf="withLabel" class="text-secondary leading-tight">
    {{ sourceTypes[machineName]?.label }}
  </span>
</div>
