import { BlockScrollStrategy, Overlay, OverlayModule } from '@angular/cdk/overlay';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';
import { CookieService } from 'ngx-cookie-service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';

import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';

import { LocationAutocompleteComponent } from './components/location-autocomplete/location-autocomplete.component';
import { SelectAutocompleteComponent } from './components/select-autocomplete/select-autocomplete.component';
import { CandidateSourceComponent } from './components/candidate-source/candidate-source.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { JobFlowStepComponent } from './components/job-flow-step/job-flow-step.component';
import { SortSelectComponent } from './components/sort-select/sort-select.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { JobStatusComponent } from './components/job-status/job-status.component';
import { FullscreenComponent } from './components/fullscreen/fullscreen.component';
import { QuickChatComponent } from './components/quick-chat/quick-chat.component';
import { ShowMoreComponent } from './components/show-more/show-more.component';
import { ShortcutsComponent } from './components/shortcuts/shortcuts.component';
import { ActivityComponent } from './components/activity/activity.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { SettingsComponent } from './components/settings/settings.component';
import { HistoryComponent } from './components/history/history.component';
import { SearchComponent } from './components/search/search.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { TeamsComponent } from './components/teams/teams.component';
import { AlertComponent } from './components/alert/alert.component';
import { UserComponent } from './components/user/user.component';
import { CardComponent } from './components/card/card.component';
import { TagsComponent } from './components/tags/tags.component';

import { IconsModule } from './modules/icons/icons.module';

import { ConfirmationDialogComponent } from './dialogs/confirm-dialog/dialog.component';
import { ScrollResetDirective } from './directives/scroll-reset/scroll-reset.directive';
import { ScrollbarDirective } from './directives/scrollbar/scrollbar.directive';
import { ConfirmationService } from './services/confirmation.service';
import { MaterialModule } from './modules/material/material.module';
import { LimitArrayPipe } from './pipes/limit-array.pipe';
import { FindByKeyPipe } from './pipes/find-by-key.pipe';
import { FirstLastPipe } from './pipes/first-last.pipe';
import { MapJoinPipe } from './pipes/map-join.pipe';

const SHARED_COMPONENTS = [
  SplashScreenComponent,
  SettingsComponent,
  QuickChatComponent,
  MessagesComponent,
  NotificationsComponent,
  SearchComponent,
  ShortcutsComponent,
  UserComponent,
  ScrollResetDirective,
  ScrollbarDirective,
  DrawerComponent,
  FullscreenComponent,
  LoadingBarComponent,
  AlertComponent,
  CardComponent,
  TeamsComponent,
  ConfirmationDialogComponent,
  FirstLastPipe,
  TagsComponent,
  FindByKeyPipe,
  ContactsComponent,
  ShowMoreComponent,
  LimitArrayPipe,
  LocationAutocompleteComponent,
  HistoryComponent,
  SelectAutocompleteComponent,
  UserAvatarComponent,
  ActivityComponent,
  MapJoinPipe,
  JobStatusComponent,
  SortSelectComponent,
  JobFlowStepComponent,
  CandidateSourceComponent
];

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    RouterModule,
    IconsModule,
    HttpClientModule,
    MomentModule,
    NgPipesModule,
    NgxMaskModule,
    ClipboardModule,
    QuillModule,
    DragDropModule
  ],
  exports: [
    ...SHARED_COMPONENTS,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    MomentModule,
    NgPipesModule,
    NgxMaskModule,
    ClipboardModule,
    QuillModule,
    DragDropModule
  ],
  declarations: [...SHARED_COMPONENTS],
  providers: [{
    provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
    useFactory: (overlay: Overlay) => (): BlockScrollStrategy => overlay.scrollStrategies.block(),
    deps: [Overlay]
  }, ConfirmationService, CookieService]
})
export class SharedModule {
}
