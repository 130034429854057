<p
  #contentContainer
  [@expandCollapse]="{value: isExpanded ? 'expanded' : 'collapsed', params: { height: initialHeight }}"
  [innerHTML]="text$ | async"
  class="overflow-hidden"
></p>

<button
  class="flex items-center mt-4 pl-2 pr-3 py-0.5 gap-1 rounded-full cursor-pointer bg-gray-200 hover:bg-gray-300"
  (click)="toggleShow()"
  *ngIf="isLongText"
>
  <mat-icon
    class="icon-size-5"
    [svgIcon]="isExpanded ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'"
  ></mat-icon>
  <span class="text-sm font-medium text-secondary">
    {{ isExpanded ? lessLabel : moreLabel }}
  </span>
</button>
