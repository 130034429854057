import { Component, Input } from '@angular/core';
import { Job } from '../../../types';

@Component({
  selector: 'app-job-status',
  templateUrl: './job-status.component.html',
})
export class JobStatusComponent {
  @Input() job!: Job;
  @Input() size: 'small' | 'large' = 'large';

  public getJobStatusTooltip(job: Job): string {
    let tooltip = 'Job ';

    switch (job?.status) {
      case 'closed':
        tooltip += 'Closed';
        break;

      case 'open':
        tooltip += 'Open';
        break;

      case 'draft':
        tooltip += 'Draft';
        break;
    }

    return tooltip;
  }
}
