import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { distinctUntilChanged, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

import { Tag } from './tags.types';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsComponent),
      multi: true
    }
  ]
})
export class TagsComponent implements ControlValueAccessor, OnDestroy {
  @Input() addTagsLabel = 'Add Tags';
  @Input() editTagsLabel = 'Edit Tags';
  @Output() private addTags = new EventEmitter();
  @Output() private editTags = new EventEmitter();
  @Output() private deleteTags = new EventEmitter();

  @ViewChild('tagsPanel') private tagsPanel: TemplateRef<any>;
  @ViewChild('tagsPanelOrigin') private tagsPanelOrigin: ElementRef;

  public tagsEditMode = false;
  public filteredTags: Tag[];
  public showNewTagControl = false;
  public newTagFC = this.formBuilder.control('', [Validators.required]);
  public searchFC = this.formBuilder.control('');
  public form = this.formBuilder.group({
    tags: this.formBuilder.array([])
  });
  public tags$: Observable<FormGroup[]> = this.searchFC.valueChanges.pipe(
    startWith(''),
    distinctUntilChanged(),
    map(val =>
      this.tagsFA.controls.filter((group: FormGroup) =>
        group.get('name')?.value
          ?.toLowerCase()
          .includes(val.toLowerCase())
      ) as FormGroup[]
    )
  );

  private allTags: Tag[];
  private tagsPanelOverlayRef: OverlayRef;
  private unsubscribe$ = new Subject();
  private tagsToUpdate = new Map<string, Tag>();
  private tagsToRemove = new Set();
  private templatePortal!: TemplatePortal;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly formBuilder: FormBuilder,
    private readonly renderer2: Renderer2,
    private readonly overlay: Overlay,
  ) {
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        map(() => this.selectedTags.map(({id}) => id))
      )
      .subscribe(value => this.setValue(value));
  }

  get tagsFA(): FormArray {
    return this.form.get('tags') as FormArray;
  }

  get selectedTags(): Tag[] {
    const selectedIds = this.tagsFA.controls.map((tagFG: FormGroup) => {
      if (tagFG.get('selected').value) {
        return tagFG.get('id').value;
      }
      return null;
    }).filter(id => id !== null);

    return this.allTags.filter(({id}) => selectedIds.includes(id));
  }

  get tags(): Tag[] {
    return this.allTags;
  }

  @Input() set tags(tags: Tag[]) {
    this.allTags = tags;
    this.filteredTags = [...tags];

    this.buildTagsFA();
  }

  public openTagsPanel(): void {
    if (this.form.disabled) {
      return;
    }

    this.tagsPanelOverlayRef = this.overlay.create({
      backdropClass: '',
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.tagsPanelOrigin.nativeElement)
        .withFlexibleDimensions(true)
        .withViewportMargin(64)
        .withLockedPosition(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          }
        ])
    });

    this.tagsPanelOverlayRef.attachments().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.renderer2.addClass(this.tagsPanelOrigin.nativeElement, 'panel-opened');

      this.tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
    });

    this.templatePortal = new TemplatePortal(this.tagsPanel, this.viewContainerRef);

    this.tagsPanelOverlayRef.attach(this.templatePortal);

    this.tagsPanelOverlayRef.backdropClick().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.renderer2.removeClass(this.tagsPanelOrigin.nativeElement, 'panel-opened');

      if (this.tagsPanelOverlayRef && this.tagsPanelOverlayRef.hasAttached()) {
        this.tagsPanelOverlayRef.detach();

        this.filteredTags = [...this.tags];
        this.tagsToUpdate.clear();
        this.tagsToRemove.clear();

        this.tagsEditMode = false;
        this.showNewTagControl = false;
        this.newTagFC.reset('');
      }

      if (this.templatePortal && this.templatePortal.isAttached) {
        this.templatePortal.detach();
      }
    });
  }

  public toggleEditMode(): void {
    this.tagsEditMode = !this.tagsEditMode;

    if (this.tagsEditMode) {
      this.searchFC.disable();
    } else {
      this.searchFC.enable();

      if (this.tagsToUpdate.size !== 0) {
        this.editTags.emit(Array.from(this.tagsToUpdate.values()));
        this.tagsToUpdate.clear();
      }

      if (this.tagsToRemove.size !== 0) {
        this.deleteTags.emit(Array.from(this.tagsToRemove.values()));
        this.tagsToRemove.clear();
      }

      const newTags = this.filteredTags.filter(tag => tag.id === null);

      if (newTags.length) {
        this.addTags.emit(newTags.map(({name}) => ({name})));
      }

      this.newTagFC.reset('');
      this.showNewTagControl = false;
    }
  }

  public createTag(): void {
    this.addTags.emit([{name: this.searchFC.value}]);
    this.searchFC.reset('');
  }

  public addNewTag(): void {
    if (this.newTagFC.valid) {
      this.showNewTagControl = false;
      this.filteredTags.push({id: null, name: this.newTagFC.value});
      this.newTagFC.reset('');
    }
  }

  public updateTagName(tag: Tag, event: any): void {
    const name = event.target.value;

    this.tagsToUpdate.set(tag.id, { ...tag, name });
  }

  public removeTag(tag: Tag, index: number): void {
    this.tagsToRemove.add(tag.id);

    this.filteredTags.splice(index, 1);
  }

  public toggleContactTag(tagFG: FormGroup): void {
    const tagSelectedFC = tagFG.get('selected');
    tagSelectedFC.patchValue(!tagSelectedFC.value);
  }

  public shouldShowCreateTagButton(): boolean {
    return !!!(this.searchFC.value === '' || this.tags.findIndex(tag => tag.name.toLowerCase() === this.searchFC.value.toLowerCase()) > -1);
  }

  public trackByFn(index: number, item: Tag): any {
    return item.id || index;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public writeValue(tags: string[]): void {
    for (const tagFG of this.tagsFA.controls) {
      if (tags?.find(id => tagFG.get('id').value === id)) {
        tagFG.get('selected').setValue(true, {
          onlySelf: true,
          emitEvent: false
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private buildTagsFA(): void {
    const existingValues = this.tagsFA.value.map((fg: any) => fg.id);

    for (let i = this.tagsFA.length - 1; i >= 0; i--) {
      const control = this.tagsFA.at(i);

      if (!this.allTags.some(tag => tag.id === control.value.id)) {
        this.tagsFA.removeAt(i);
      }
    }

    for (const tag of this.allTags) {
      const index = existingValues.indexOf(tag.id);

      if (index === -1) {
        this.tagsFA.push(this.buildTagFG(tag.id, tag.name));
      } else {
        const control = this.tagsFA.at(index);
        if (control.value.name !== tag.name) {
          control.patchValue({ name: tag.name });
        }
      }
    }

    if (this.tagsPanelOverlayRef?.hasAttached()) {
      this.tagsPanelOverlayRef?.detach();
    }

    this.tagsPanelOverlayRef?.attach(this.templatePortal);
  }

  private buildTagFG(id: any, name: string, selected: boolean = false): FormGroup {
    return this.formBuilder.group({
      id,
      name,
      selected
    });
  }

  private setValue(value: any): void {
    this.onChange(value);
    this.onTouched();
  }

  private onTouched = (): any => {};
  private onChange = (m: any): any => {};
}
