import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-job-flow-step',
  templateUrl: './job-flow-step.component.html'
})
export class JobFlowStepComponent {
  @Input() feedback: any;
  @Output() updateStep = new EventEmitter();

  constructor() {}
}
