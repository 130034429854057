import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import { AfterViewInit, Component, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-location-autocomplete',
  templateUrl: './location-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationAutocompleteComponent),
      multi: true
    }
  ]
})
export class LocationAutocompleteComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() label = 'Location';
  @Input() id?: string;

  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  public locationFilterFC = this.formBuilder.control(null);
  public locationOptions$: Observable<string> = this.locationFilterFC.valueChanges.pipe(
    distinctUntilChanged(),
    debounceTime(700),
    filter(address => !!address && address.length > 1),
    switchMap(address => this.helperService.locationAutocomplete({address}).pipe(
      map(({addresses}) => addresses),
      catchError(() => of([]))
    ))
  );

  private unsubscribe$ = new Subject();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly helperService: HelperService
  ) {
    this.locationFilterFC.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.setValue(value));
  }

  @Input() set required(isRequired: boolean) {
    if (isRequired) {
      this.locationFilterFC.setValidators(Validators.required);
    } else {
      this.locationFilterFC.clearValidators();
    }

    this.locationFilterFC.updateValueAndValidity();
  }

  ngAfterViewInit(): void {
    this.trigger
      .panelClosingActions
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((e) => {
        if (!(e && e.source)) {
          this.locationFilterFC.setValue('');
          this.trigger.closePanel();
        }
      });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.locationFilterFC.disable();
    } else {
      this.locationFilterFC.enable();
    }
  }

  public writeValue(address: string): void {
    this.locationFilterFC.setValue(address);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private setValue(value: any): void {
    this.onChange(value);
    this.onTouched();
  }

  private onTouched = (): any => {
  };
  private onChange = (m: any): any => {
  };
}
