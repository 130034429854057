import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';

import { Option } from './select-autocomplete.types';

@Component({
  selector: 'app-select-autocomplete',
  templateUrl: './select-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectAutocompleteComponent),
      multi: true,
    },
  ]
})
export class SelectAutocompleteComponent implements ControlValueAccessor, OnDestroy, Validator {
  @Input() label!: string;
  @Input() id?: string;
  @Input() showChips = false;
  @Input() multiple = true;
  @Input() sortSelectedToTop = true;
  @Input() maxChips: number | null = null;

  public selectOptionsMap!: Map<string, Option>;
  public selectFC = this.formBuilder.control([]);
  public selectFilterFC = this.formBuilder.control(null);
  public options$: Observable<any[]> = this.selectFilterFC.valueChanges.pipe(
    startWith(''),
    map((value: string) => {
      const selectedValues: any = this.selectFC.value || [];

      const filteredOptions = this.options.filter(({title}) => title.toLowerCase().includes(value));

      if (this.sortSelectedToTop) {
        return filteredOptions.sort((a: any, b: any) => {
          const aSelected = selectedValues.includes(a.id);
          const bSelected = selectedValues.includes(b.id);

          return bSelected - aSelected;
        });
      }

      return filteredOptions;
    }),
  );

  private selectOptions!: Option[];
  private unsubscribe$ = new Subject();

  constructor(private readonly formBuilder: FormBuilder) {
    this.selectFC.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.setValue(value));
  }

  get options(): Option[] {
    return this.selectOptions;
  }

  @Input() set options(options: Option[]) {
    this.selectOptions = options;
    this.selectOptionsMap = new Map(options.map(option => [option.id, option]));
  }

  @Input() set required(isRequired: boolean) {
    if (isRequired) {
      this.selectFC.setValidators(Validators.required);
    } else {
      this.selectFC.clearValidators();
    }

    this.selectFC.updateValueAndValidity();
  }

  public removeTag(id: string): void {
    const updatedValue = this.selectFC.value.filter((tag: string) => tag !== id);
    this.selectFC.setValue(updatedValue);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectFC.disable();
    } else {
      this.selectFC.enable();
    }
  }

  public writeValue(options: Option[]): void {
    this.selectFC.setValue(options, {emitEvent: false, onlySelf: true});
  }

  public validate(control: AbstractControl<any, any>): ValidationErrors | null {
    if (control.hasValidator(Validators.required)) {
      this.selectFC.setValidators(Validators.required);
    }

    return null;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private setValue(value: any): void {
    this.onChange(value);
    this.onTouched();
  }

  private onTouched = (): any => {
  };
  private onChange = (m: any): any => {
  };
}
